import React from 'react';
import { Card } from 'antd';

const ConstantsPanel = () => {
  const constants = [
    { label: 'Electricity Rate (peak)', value: '£0.28/Kwh' },
    { label: 'Electricity Rate (off-peak)', value: '£0.22/Kwh' },
    { label: 'Room Energy Rate', value: '1.5kW/hr' },
    { label: 'Check-In time', value: '3pm' },
    { label: 'Check-Out time', value: '10:30am' },
    { label: 'Cleaning Time Lag', value: '2.5hrs' },
    { label: 'CO2 / KWh', value: '0.233 kg' }
  ];

  return (
    <Card 
      className="card-wrapper-dark"
      style={{ height: '100%' }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 50px)',
        padding: '20px'
      }}>
        {constants.map((constant, index) => (
          <div key={index} style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '16px',
            fontSize: '20px'
          }}>
            <span style={{ color: '#ffffff' }}>{constant.label}</span>
            <span style={{ color: '#ffffff', fontWeight: 'bold' }}>{constant.value}</span>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default ConstantsPanel;
