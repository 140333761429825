import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { Layout, Menu, Row, Col, Dropdown, Button } from 'antd';
import { UserOutlined, SettingOutlined, GatewayOutlined, LogoutOutlined, BarChartOutlined } from '@ant-design/icons';

import './component.scss';

const { Header } = Layout;

const MainHeader = () => {
  const { signOut } = useAuth();
  const history = useHistory();

  const handleSignOut = async () => {
    await signOut();

    history.push('/login');
  };

  const items = [
    {
      label: (
        <Link to="/account-settings">Account Settings</Link>
      ),
      key: '1',
      icon: <UserOutlined />,
    },
    {
      label: (
        <Link to="/settings">Hotel Settings</Link>
      ),
      key: '2',
      icon: <SettingOutlined />,
    },
    {
      label: (
        <Link to="/gateways">Gateways</Link>
      ),
      key: '3',
      icon: <GatewayOutlined />,
    },
    {
      label: (
        <Link to="/overview">Reports</Link>
      ),
      key: '4',
      icon: <BarChartOutlined />,
    },
    {
      label: (
        <span onClick={() => handleSignOut()}>Log out</span>
      ),
      key: '5',
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <Header className="site-header">
      <Row align="middle" className="site-header-row">
        <Col span={12}>
          <Link to="/" className="logo">
            <div className="logo-img"></div>
          </Link>
        </Col>
        <Col span={12}>
          <Dropdown menu={{items}}  placement="bottomRight" trigger="click">
              <UserOutlined />
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};

export default MainHeader;
