import React from 'react';
import { Modal, Tabs, Progress, Typography, Button } from 'antd';
import PowerGauge from './gauge';

import './component.scss';

const { Paragraph } = Typography;

const RoomView = ({
  modalVisible,
  cancelModal,
  selectedRoom,
  roomData,
  handleSendGatewayCommand,
  sendingCommand,
}) => {
  const items = [
    {
      key: '1',
      label: 'Status',
      children: (
        <>
          <div className="room-view__chart2">
            <PowerGauge value={roomData?.room?.powerSaving?.saving ?? 0} />
          </div>
          <div className="room-view__control">
            <Paragraph>Room Control</Paragraph>
            <Button
              onClick={() => {
                handleSendGatewayCommand('ROOM_POWER_ON');
              }}
              disabled={sendingCommand || !roomData?.room?.gateway}
            >
              Room On
            </Button>
            <Button
              onClick={() => {
                handleSendGatewayCommand('ROOM_POWER_OFF');
              }}
              disabled={sendingCommand || !roomData?.room?.gateway}
            >
              Room Off
            </Button>
          </div>
          <div>
            <h3>Battery</h3>
            <Progress percent={roomData?.room.batteryLevel ?? -1} />
          </div>
        </>
      )
    },
    {
      key: '2', 
      label: 'Devices',
      children: 'In progress'
    }
  ];

  return (
    <>
      <Modal
        title={`Room: ${selectedRoom.name}`}
        open={modalVisible}
        onCancel={cancelModal}
        onOk={cancelModal}
        className="room-view"
      >
        <Tabs defaultActiveKey="1" items={items} />
      </Modal>
    </>
  );
};

export default RoomView;
