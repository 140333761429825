import React from 'react';
import { Row, Col } from 'antd';
import OccupancyPanel from './components/occupancyPanel';
import RecentEnergySavingsPanel from './components/recentEnergySavingsPanel';
import AnnualEnergySavingsPanel from './components/annualEnergySavingsPanel';
import OperationsPanel from './components/operationsPanel';
import SavingsPanel from './components/savingsPanel';
import ConstantsPanel from './components/constantsPanel';
import BatteryPanel from './components/batteryPanel';
import AppLayout from '../../components/AppLayout/component';

const OverviewContainer = () => {
  return (
    <AppLayout>
      <div className="overview">
        <Row
          gutter={[16, 16]}
          type="flex"
          justify="center">
          <Col xs={24} sm={24} md={24} style={{ paddingBottom: '24px' }}>
            <h2 className="ant-typography">Energy Savings</h2>
            <RecentEnergySavingsPanel title="Recent Energy Savings" />
          </Col>
        </Row>
        <br />
        <Row
          gutter={[16, 16]}
          type="flex"
          justify="center">
          <Col xs={24} sm={24} md={12} style={{ paddingBottom: '24px' }}>
            <h2 className="ant-typography">Energy Savings</h2>
            <SavingsPanel />
          </Col>
          <Col xs={24} sm={24} md={12} style={{ paddingBottom: '24px' }}>
            <h2 className="ant-typography">Total Energy Savings</h2>
            <AnnualEnergySavingsPanel />
          </Col>
        </Row>
        <br />
        <Row
          gutter={[16, 16]}
          type="flex"
          justify="center">
          <Col xs={24} sm={24} md={12} style={{ paddingBottom: '24px' }}>
            <h2 className="ant-typography">Battery Status</h2>
            <BatteryPanel />
          </Col>
          <Col xs={24} sm={24} md={12} style={{ paddingBottom: '24px' }}>
            <h2 className="ant-typography">Occupancy</h2>
            <OccupancyPanel />
          </Col>
        </Row>
        <br />
        <Row
          gutter={[16, 16]}
          type="flex"
          justify="center">
          <Col xs={24} sm={24} md={12} style={{ paddingBottom: '24px' }}>
            <h2 className="ant-typography">Operations</h2>
            <OperationsPanel />
          </Col>
          <Col xs={24} sm={24} md={12} style={{ paddingBottom: '24px' }}>
            <h2 className="ant-typography">Constant Factors</h2>
            <ConstantsPanel />
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
};

export default OverviewContainer;
