import React, { useState } from 'react';
import { Card } from 'antd';
import { Bar } from '@ant-design/plots';
import { ThunderboltOutlined, PoundOutlined } from '@ant-design/icons';
import { COLORS } from '../../../constants/colors';
/*
Energy Saved Overall %
Bars show "Actual energy total " and "Energy w/o ALL/OFF" (both estimated)

Annual Total Saving
*/

const AnnualEnergySavingsPanel = () => {
  const [data, setData] = useState([
    { id: 'energy_without', type: 'Energy without\nALL | OFF', value: 812683, cost: 203171 },
    { id: 'energy_with', type: 'Energy with\nALL | OFF', value: 496675, cost: 124169 },
  ]);

  const config = {
    data,
    xField: 'id',
    yField: 'value',
    colorField: 'type',
    legend: false,
    style: {
      maxWidth: '120',
      fill: ({ id }) => {
        if (id === 'energy_with') return COLORS.chartGreen;
        return COLORS.chartBrown;
      },
    },
    axis: {
      x: {
        labelFill: 'white',
        labelFontSize: 16,
        labelFontWeight: 'bold',
        labelFormatter: (text, item, index) => {
          //console.log(text, item, index);
          switch (text) {
            case 'energy_without':
              return `Energy without\nALL | OFF\n£${data.find(item => item.id === 'energy_without').cost.toLocaleString('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
            case 'energy_with':
              return `Energy with\nALL | OFF\n£${data.find(item => item.id === 'energy_with').cost.toLocaleString('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
            default:
              return '';
          }
        },
      },
      y: {
        label: false
      }
    },
  };

  return (
    <Card
      className="card-wrapper-dark" 
      style={{ height: '100%' }}>
      <div style={
        { 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: 'calc(100% - 50px)' 
        }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '24px', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', width: '100%', justifyContent: 'space-evenly' }}>
            
          <div className='ant-typography' style={{ textAlign: 'center' }}>
              <h2 className='ant-typography'>
                <PoundOutlined style={{marginRight: '8px'}} />
                Annual Total Saving
              </h2>
              <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
               £79,023
              </div>
            </div>

            <div className='ant-typography' style={{ textAlign: 'center' }}>
              
              <h2 className='ant-typography'>
                <ThunderboltOutlined style={{marginRight: '8px'}} />
                Total Energy Saved
              </h2>
              <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                37.4%
              </div>
            </div>

          </div>
          <div style={{ width: '100%', height: '400px' }}>
          <Bar {...config} />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AnnualEnergySavingsPanel;