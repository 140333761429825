import React from 'react';
import { Modal, Input } from 'antd';

const DeleteAccount = ({
  modalVisible,
  cancelModal,
  handlePasswordChange,
  onDeleteUser,
  loading,
}) => {
  return (
    <>
      <Modal
        title="Delete Account"
        open={modalVisible}
        onOk={onDeleteUser}
        onCancel={cancelModal}
        confirmLoading={loading}
      >
        <h2>Are you sure?</h2>
        <p>
          This operation is permanent. If you are the last user, this will remove all hotels,
          floors, rooms and historical data. Please enter password to confirm removing account.
        </p>
        <Input.Password placeholder="Enter your password." onChange={handlePasswordChange} />
      </Modal>
    </>
  );
};

export default DeleteAccount;
