import React from 'react';
import { Gauge } from '@ant-design/plots';

const max = 40;

const PowerGauge = ({ value }) => {
  const demoConfig = {
    width: 720,
    height: 720,
    autoFit: true,
    data: {
      target: 120,
      total: 400,
      name: 'score',
    },
    legend: false,
  }
  
  const config = {
    data: {
      target: (value ?? 0) / max,
      total: max,
      thresholds: [10,20,40]
    },
    width: 400,
    height: 400,
    autoFit: true,
    legend: false,
    scale: {
      color: {
        range: ['#F4664A', '#FAAD14', '#30BF78'],
      }
    },
    style: {
      textContent: (target, total) => `${target}% Saving`,
      style: {
        fontSize: '30px',
        lineHeight: '36px',
      }
    },
    axis: {
      y: {
        label: true,
        formatter: (text, item, index) => '',
      },
      x: {
        label: false,
        formatter: (text, item, index) => '',
      }

    },
  };
  return <Gauge {...config} />;
};

export default PowerGauge;
