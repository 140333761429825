import React, { useState } from 'react';
import { Typography, Card, Row, Col, Input, Button, Form, Space, message } from 'antd';
import { Switch } from 'antd';
import AppLayout from '../../components/AppLayout/component';
import { DeleteHotel } from './modals/DeleteHotel';
import './component.scss';
import { CopyOutlined } from '@ant-design/icons';
const { Title } = Typography;

const HotelSettings = ({
  hotel,
  code,
  updateHotelData,
  handleEditHotelClick,
  handleEnableRegClick,
  handleDisableRegClick,
  deleteHotelModalVisible,
  cancelDeleteHotelModal,
  showDeleteHotelModal,
  loading,
}) => {
  const { name, id } = hotel;

  const onHotelDataFormFinish = async (data) => {
    try {
      await updateHotelData(data);
      message.success('Hotel settings saved successfully');
    } catch (error) {
      message.error('Failed to save hotel settings');
    }
  };

  return (
    <AppLayout>
      <div className="settings-page">
        <Title level={4}>Hotel Settings</Title>
        <Card className="card-wrapper">
          <Form
            name="hotelData"
            key={id}
            layout="vertical"
            onFinish={onHotelDataFormFinish}
            initialValues={{
              hotelName: name,
            }}
          >
            <Row>
              <Col span={24}>
                <Title level={5}>Hotel Settings</Title>
                <Form.Item
                  name="hotelName"
                  label="Hotel name"
                  rules={[{ required: true, message: 'Please input Hotel name' }]}
                  style={{ marginBottom: '8px' }}
                >
                  <Input placeholder="Hotel name" style={{ maxWidth: '300px' }} />
                </Form.Item>
                <Form.Item>
                  <Button type='primary' htmlType="submit" disabled={loading}>
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <hr />
          <br />
          <Row>
            <Col span={24}>
              <Title level={5}>Hotel registration code</Title>
              <br />
              <Space>
                <Switch
                  checked={!!code}
                  disabled={loading}
                  onChange={(checked) => {
                    if (checked) {
                      handleEnableRegClick();
                    } else {
                      handleDisableRegClick();
                    }
                  }}
                />
                
                <Input 
                  value={code} 
                  readOnly 
                  style={{ width: '200px', resize: 'none', whiteSpace: 'nowrap', overflow: 'hidden' }}
                  suffix={
                    <Button 
                      type="text" 
                      icon={<CopyOutlined />} 
                      onClick={() => navigator.clipboard.writeText(code)}
                    />
                  }
                />
              
              </Space>
            </Col>
          </Row>
          <br />
          <hr />
          <br />
          <Row>
            <Col span={24}>
              <Title level={5}>Advanced Options</Title>
              <br />
              <Space>
                <Button type="primary" onClick={handleEditHotelClick}>
                  Edit Hotel Layout
                </Button>
                <Button danger type="primary" disabled={loading} onClick={showDeleteHotelModal}>
                  Delete Hotel
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
      </div>
      <DeleteHotel modalVisible={deleteHotelModalVisible} cancelModal={cancelDeleteHotelModal} />
    </AppLayout>
  );
};

export default HotelSettings;
