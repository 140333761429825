import React, { useContext } from 'react';
import { Modal, Select } from 'antd';
import { useQuery } from '@apollo/client';

import { GET_ROOMS_WITH_FLOOR } from '../../../../queries/room';
import UserContext from '../../../../contexts/User';
import { HotelContext } from '../../../../contexts/Hotel';
import { useState } from 'react';

const { Option } = Select;

const LinkRoomModal = ({
  modalVisible,
  cancelModal,
  linkRoomHandler,
  confirmLoading,
  handleLinkRoomSelect,
  selectedRoomIdtoLink,
}) => {
  const tenantId = useContext(UserContext);
  const { state: hotelState } = useContext(HotelContext);
  const [rooms, setRooms] = useState([]);

  useQuery(GET_ROOMS_WITH_FLOOR, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    variables: {
      hotelId: hotelState.hotels[0].id,
    },
    onCompleted(data) {
      const arrayForSort = [...data.rooms];
      setRooms(
        arrayForSort
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((room) => {
            return !room.gateway;
          })
      );
    },
  });

  return (
    <>
      <Modal
        title="Link a Gateway to a Room"
        open={modalVisible}
        onCancel={() => {
          cancelModal();
        }}
        confirmLoading={confirmLoading}
        okText="Link"
        onOk={() => {
          linkRoomHandler();
        }}
      >
        <Select
          style={{ width: '100%' }}
          onChange={(id) => handleLinkRoomSelect(id)}
          value={selectedRoomIdtoLink}
        >
          {rooms.map((room, key) => {
            return (
              <Option value={room.id} key={key}>
                {`${room.name}, ${room.floor.name} floor`}
              </Option>
            );
          })}
        </Select>
      </Modal>
    </>
  );
};

export default LinkRoomModal;
