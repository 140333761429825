import React from 'react';
import { Card } from 'antd';
import { Pie } from '@ant-design/plots';
import { WarningOutlined, CloudOutlined, FireOutlined, DisconnectOutlined } from '@ant-design/icons';
const OccupancyPanel = () => {
  // Example data - replace with real data
  const data = [
    { type: 'Offline', value: 4 },
    { type: 'Online', value: 76 }, 
  ];
  
  const online = data.find(item => item.type === 'Online').value;
  const offline = data.find(item => item.type === 'Offline').value;
  const total = data.reduce((sum, item) => sum + item.value, 0);
  const config = {
    data,
    legend: false,
    angleField: 'value',
    colorField: 'type',
    innerRadius: 0.6,
    label: {
      text: 'value',
      style: {
        fontWeight: 'bold',
      },
    },
    style: {
      fill: ({ type }) => {
        if (type === 'Offline') return '#696969';
        return '#02FA62';
      },
    },
    annotations: [
      {
        type: 'text',
        style: {
          text: `Online \n${online} / ${total}`,
          x: '50%',
          y: '50%',
          textAlign: 'center',
          fontSize: 26,
          fontStyle: 'bold',
          fill: '#ffffff'
        },
      },
    ],
  };

  return (
    <Card 
      className="card-wrapper-dark" 
      style={{ height: '100%' }}>
      <div style={
        { 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: 'calc(100% - 50px)' 
        }}>
        <div style={{ display: 'flex', width: '100%', gap: '24px', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ flex: 7, display: 'flex', justifyContent: 'center' }}>
            <Pie {...config} />
          </div>
          <div style={{ flex: 3, display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <div className='ant-typography' style={{ textAlign: 'center' }}>
              <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                3 <WarningOutlined style={{marginLeft: '8px'}} />
              </div>
              <h4 className='ant-typography'>
                Active Room Alerts
              </h4>
            </div>
            <div className='ant-typography' style={{ textAlign: 'center' }}>
              <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                4 <DisconnectOutlined style={{marginLeft: '8px'}} />
              </div>
              <h4 className='ant-typography'>
                Rooms Offline
              </h4>
            </div>
          </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default OccupancyPanel;
