import React from 'react';
import { Card } from 'antd';
import { Pie } from '@ant-design/plots';

const OccupancyPanel = () => {
  // Example data - replace with real data
  const data = [
    { type: 'Occupied', value: 36 },
    { type: 'Vacant', value: 44 }, 
  ];
  
  const occupied = data.find(item => item.type === 'Occupied').value;
  const vacant = data.find(item => item.type === 'Vacant').value;
  const total = data.reduce((sum, item) => sum + item.value, 0);
  const config = {
    data,
    legend: false,
    angleField: 'value',
    colorField: 'type',
    style: {
      fill: ({ type }) => {
        if (type === 'Occupied') return '#02FA62';
        return '#696969';
      },
    },
    innerRadius: 0.6,

    annotations: [
      {
        type: 'text',
        style: {
          text: `Occupied\n${occupied} / ${total}`,
          x: '50%',
          y: '50%',
          textAlign: 'center',
          fontSize: 26,
          fontStyle: 'bold',
          fill: '#ffffff'
        },
      },
    ],
  };

  return (
    <Card 
      className="card-wrapper-dark" 
      style={{ height: '100%' }}>
      <div style={
        { 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: 'calc(100% - 50px)' 
        }}>
        <div style={{ display: 'flex', width: '70%', gap: '24px', justifyContent: 'center', alignItems: 'center', margin: '0 auto' }}>
            <Pie {...config} />
        </div>
      </div>
    </Card>
  );
};

export default OccupancyPanel;
