const COLORS = {
  primaryYellow:  '#fade02',
  chartGreen: '#02FA62',
  chartBlue: '#021EFA',
  chartBrown: '#716429', //Himalaya
  chartPink: '#FA029A',
  chartWhite: '#ffffff',
  imperialRed: '#ED2939'
}

export { COLORS };